import React, { useState } from 'react';
import axios from 'axios';
import './VinDetails.css'; // Ensure you have a separate CSS file for styling

const VinnForm = () => {
  const [vin, setVin] = useState('');
  const [message, setMessage] = useState('');
  const [vinDetails, setVinDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const transliterate = (c) => {
    return '0123456789.ABCDEFGH..JKLMN.P.R..STUVWXYZ'.indexOf(c) % 10;
  };

  const getCheckDigit = (vin) => {
    const map = '0123456789X';
    const weights = '8765432X098765432';
    let sum = 0;
    for (let i = 0; i < 17; ++i) sum += transliterate(vin[i]) * map.indexOf(weights[i]);
    return map[sum % 11];
  };

  const validateVin = (vin) => {
    if (vin.length !== 17) return false;
    return getCheckDigit(vin) === vin[8];
  };

  const handleVinChange = (e) => {
    setVin(e.target.value.toUpperCase());
    setMessage('');
    setVinDetails(null);
  };

  const fetchVinDetails = async (vin) => {
    setLoading(true);
    const config = {
      method: 'get',
      url: `https://api.vehicledatabases.com/vin-decode/${vin}`,
      headers: {
        'x-AuthKey': '3a4b3c05de634ec98e38a3406717362a' // Replace with your actual auth key
      }
    };

    try {
      const response = await axios(config);
      const data = response.data;

      if (data) {
        setVinDetails(data.data);
        setMessage('VIN details fetched successfully!');
      } else {
        setMessage('No details found for this VIN.');
      }
    } catch (error) {
      setMessage('Error fetching VIN details.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateVin(vin)) {
      setMessage('Invalid VIN. Please enter a valid 17-character VIN.');
    } else {
      fetchVinDetails(vin);
    }
  };

  return (
    <div className='vin-validator'>
      <h2 className='form-heading'>VIN Validation</h2>
      <form onSubmit={handleSubmit}>
        <input
          className='vin-input'
          id='vin'
          type='text'
          value={vin}
          placeholder='ENTER VIN'
          onChange={handleVinChange}
          maxLength='17'
        />
        <button type='submit' className='validate-button'>
          Validate VIN
        </button>
      </form>
      {loading && <p>Loading...</p>}
      {message && <p className='validation-message'>{message}</p>}

      {/* Display VIN details in a structured form */}
      {vinDetails && (
        <div className='vin-details'>
          <h3>Vehicle Information</h3>

          {/* Basic Information */}
          <div className='section'>
            <h4>Basic Information</h4>
            <div className='field'>
              <label>VIN:</label>
              <span>{vinDetails.intro.vin}</span>
            </div>
            <div className='field'>
              <label>Make:</label>
              <span>{vinDetails.basic.make}</span>
            </div>
            <div className='field'>
              <label>Model:</label>
              <span>{vinDetails.basic.model}</span>
            </div>
            <div className='field'>
              <label>Year:</label>
              <span>{vinDetails.basic.year}</span>
            </div>
            <div className='field'>
              <label>Trim:</label>
              <span>{vinDetails.basic.trim}</span>
            </div>
            <div className='field'>
              <label>Body Type:</label>
              <span>{vinDetails.basic.body_type}</span>
            </div>
            <div className='field'>
              <label>Vehicle Type:</label>
              <span>{vinDetails.basic.vehicle_type}</span>
            </div>
          </div>

          {/* Engine Information */}
          <div className='section'>
            <h4>Engine Information</h4>
            <div className='field'>
              <label>Engine Size:</label>
              <span>{vinDetails.engine.engine_size}</span>
            </div>
            <div className='field'>
              <label>Engine Capacity:</label>
              <span>{vinDetails.engine.engine_capacity}</span>
            </div>
          </div>

          {/* Manufacturer Information */}
          <div className='section'>
            <h4>Manufacturer Information</h4>
            <div className='field'>
              <label>Manufacturer:</label>
              <span>{vinDetails.manufacturer.manufacturer}</span>
            </div>
            <div className='field'>
              <label>Region:</label>
              <span>{vinDetails.manufacturer.region}</span>
            </div>
            <div className='field'>
              <label>Country:</label>
              <span>{vinDetails.manufacturer.country}</span>
            </div>
            <div className='field'>
              <label>Plant City:</label>
              <span>{vinDetails.manufacturer.plant_city}</span>
            </div>
          </div>

          {/* Drivetrain Information */}
          <div className='section'>
            <h4>Drivetrain Information</h4>
            <div className='field'>
              <label>Drive Type:</label>
              <span>{vinDetails.drivetrain.drive_type}</span>
            </div>
          </div>

          {/* Fuel Information */}
          <div className='section'>
            <h4>Fuel Information</h4>
            <div className='field'>
              <label>Fuel Type:</label>
              <span>{vinDetails.fuel.fuel_type}</span>
            </div>
          </div>

          {/* Restraint System */}
          <div className='section'>
            <h4>Restraint System</h4>
            <div className='field'>
              <label>Other Restraints:</label>
              <span>{vinDetails.restraint.others}</span>
            </div>
          </div>

          {/* Dimensions */}
          <div className='section'>
            <h4>Dimensions</h4>
            <div className='field'>
              <label>GVWR:</label>
              <span>{vinDetails.dimensions.gvwr}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VinnForm;
