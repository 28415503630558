
import './App.css';
import backgroundImage from './assets/images/bg-image.jpeg';
import { Blockss } from './components/Blockss';
import VinForm from './VinForm';
import Vinn from './vinn';
import VinnForm from './vinnForm';
import { VinValidator } from './VinValidator';




function App() {

  
  return (
    <div className="App">

{/* style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }}
  */}
      {/* <VinForm /> */}
     {/* <Blockss /> */}
     {/* <VinValidator /> */}
     {/* < Vinn/> */}
      
    
        <VinnForm/>

    </div>
  
  );
}

export default App;
